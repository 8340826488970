import React from "react";
import { PageLayout } from "../components/PageLayout";
import { TermsOfUse } from "../components/TermsOfUse";
import { termsOfUsePageHeroImage } from "../images";

const TermsOfUsePage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${termsOfUsePageHeroImage})`}
      heroOverlayLeadElement="Terms of Use"
      heroOverlaySecondaryElement="Terms of Use"
    >
      <TermsOfUse />
    </PageLayout>
  );
};

export default TermsOfUsePage;
